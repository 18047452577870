import React, { useState , useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';

const nflWeeks = [
  { week: '1', startDate: '2024-09-05', endDate: '2024-09-11' },
  { week: '2', startDate: '2024-09-12', endDate: '2024-09-18' },
  { week: '3', startDate: '2024-09-19', endDate: '2024-09-25' },
  { week: '4', startDate: '2024-09-26', endDate: '2024-10-02' },
  { week: '5', startDate: '2024-10-03', endDate: '2024-10-09' },
  { week: '6', startDate: '2024-10-10', endDate: '2024-10-16' },
  { week: '7', startDate: '2024-10-17', endDate: '2024-10-23' },
  { week: '8', startDate: '2024-10-24', endDate: '2024-10-29' },
  { week: '9', startDate: '2024-10-31', endDate: '2024-11-06' },
  { week: '10', startDate: '2024-11-07', endDate: '2024-11-13' },
  { week: '11', startDate: '2024-11-14', endDate: '2024-11-20' },
  { week: '12', startDate: '2024-11-21', endDate: '2024-11-23' },
  { week: '13', startDate: '2024-11-28', endDate: '2024-12-04' },
  { week: '14', startDate: '2024-12-05', endDate: '2024-12-11' },
  { week: '15', startDate: '2024-12-12', endDate: '2024-12-18' },
  { week: '16', startDate: '2024-12-19', endDate: '2024-12-25' },
  { week: '17', startDate: '2024-12-26', endDate: '2024-01-01' },
  { week: '18', startDate: '2025-01-02', endDate: '2025-01-05' }
];

const ncaafWeeks = [
  { week: 1, startDate: '2024-08-24', endDate: '2024-09-04' },
  { week: 2, startDate: '2024-09-06', endDate: '2024-09-09' },
  { week: 3, startDate: '2024-09-12', endDate: '2024-09-16' },
  { week: 4, startDate: '2024-09-19', endDate: '2024-09-24' },
  { week: 5, startDate: '2024-09-26', endDate: '2024-09-30' },
  { week: 6, startDate: '2024-10-03', endDate: '2024-10-05' },
  { week: 7, startDate: '2024-10-08', endDate: '2024-10-12' },
  { week: 8, startDate: '2024-10-15', endDate: '2024-10-19' },
  { week: 9, startDate: '2024-10-22', endDate: '2024-11-27' },
  { week: 10, startDate: '2024-10-29', endDate: '2024-11-02' },
  { week: 11, startDate: '2024-11-05', endDate: '2024-11-09' },
  { week: 12, startDate: '2024-11-12', endDate: '2024-11-16' },
  { week: 13, startDate: '2024-11-19', endDate: '2024-12-23' },
  { week: 14, startDate: '2024-11-26', endDate: '2024-11-30' }
];


const ScoresMachup = ({ oddsData, ncaafScores }) => {
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(nflWeeks[0].startDate),
    endDate: new Date(nflWeeks[0].endDate),
  });
  const [selectedLeague, setSelectedLeague] = useState('NFL');

  useEffect(() => {
    const weeks = selectedLeague === 'NFL' ? nflWeeks : ncaafWeeks;

    // Find the first week with data
    const firstWeekWithData = weeks.find((week) => {
      const startDate = new Date(week.startDate);
      const endDate = new Date(week.endDate);
      const games = selectedLeague === 'NFL' ? oddsData : ncaafScores;

      // Filter the games for this week
      const filteredGames = games.filter((game) => {
        const gameDate = new Date(game.commence_time);
        return gameDate >= startDate && gameDate <= endDate;
      });

      // Return true if there are games in this week
      return filteredGames.length > 0;
    });

    // Set default selected date range to the first week that has data
    if (firstWeekWithData) {
      setSelectedDateRange({
        startDate: new Date(firstWeekWithData.startDate),
        endDate: new Date(firstWeekWithData.endDate),
      });
    }
  }, [selectedLeague, oddsData, ncaafScores]);


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleClickId = (week) => {
    const selectedWeeks = selectedLeague === 'NFL' ? nflWeeks : ncaafWeeks;
    const selectedWeek = selectedWeeks.find(w => w.week === week);
    setSelectedDateRange({
      startDate: new Date(selectedWeek.startDate),
      endDate: new Date(selectedWeek.endDate)
    });
  };

  const filterGamesByDate = (games, startDate, endDate) => {
    return games.filter((game) => {
      const gameDate = new Date(game.commence_time);
      return gameDate >= startDate && gameDate <= endDate;
    });
  };

  const filteredGames = selectedLeague === 'NFL' && selectedDateRange && oddsData.length > 0
    ? filterGamesByDate(oddsData, selectedDateRange.startDate, selectedDateRange.endDate)
    : selectedLeague === 'NCAAF' && selectedDateRange && ncaafScores.length > 0
    ? filterGamesByDate(ncaafScores, selectedDateRange.startDate, selectedDateRange.endDate)
    : [];

  const weeksToDisplay = selectedLeague === 'NFL' ? nflWeeks : ncaafWeeks;

  return (
    <div id="score" role="tabpanel" aria-labelledby="score-tab">
      <div className="circaselect-box score-new">
       <select value={selectedLeague} onChange={(e) => setSelectedLeague(e.target.value)} className="select-box">
        <option value="NFL">NFL</option>
        <option value="NCAAF">NCAAF</option>
      </select>
      </div>
      <div id="odds-api">
      <h2>{selectedLeague === 'NFL' ? 'NFL Scores & Matchups' : 'NCAAF Scores & Matchups'}</h2>
      <div className="slide-div">
      <Slider {...settings} className="bg-white fs-8 text-uppercase d-flex flex-grow-1 flex-row justify-content-center align-items-center flex-nowrap text-center fw-normal rounded-2 text-nowrap">
            {weeksToDisplay.map(week => (
              <div key={week.week} className="item" onClick={() => handleClickId(week.week)}>
                <div className="date">Week {week.week}</div>
                <div className="dateRange">{new Date(week.startDate).toLocaleDateString()} - {new Date(week.endDate).toLocaleDateString()}</div>
              </div>
            ))}
          </Slider>
      </div>
      <div
          
          id={`game`}
          className="score-box11"
          style={{ display: 'block' }}
        >
          <div className="row">
           
      {filteredGames && filteredGames.length > 0 ? (
          filteredGames.map((game, index) => {
            const bookmakers = game.bookmakers.find(b => b.key === 'draftkings');
            const spreadMarket = bookmakers?.markets.find(m => m.key === 'spreads');
            const totalsMarket = bookmakers?.markets.find(m => m.key === 'totals');

            const awayOutcome = spreadMarket?.outcomes.find(outcome => outcome.name === game.away_team);
            const homeOutcome = spreadMarket?.outcomes.find(outcome => outcome.name === game.home_team);
            const overOutcome = totalsMarket?.outcomes.find(outcome => outcome.name === 'Over');
            const underOutcome = totalsMarket?.outcomes.find(outcome => outcome.name === 'Under');
          return (
            <div className="col-md-6" key={index}>
                <div className="Kansas-city"  >
                  <h2>
                    {game.away_team} @ {game.home_team}
                  </h2>
                  <div className="Kansas-box22">
                    <div className="covers-CoversScoreboard-gameBox-Status">
                      <span className="covers-badge preGame-time">
                        {new Date(game.commence_time).toLocaleString()}
                      </span>
                    </div>
                    <div className="covers-CoversScoreboard-gameBox-teamAndOdds">
                      <div className="covers-CoversScoreboard-gameBox-Teams">
                        <div className="covers-CoversScoreboard-gameBox-Team">
                          <Link to="#">
                            <img
                              loading="lazy"
                              src={`images/${game.away_team}.svg`}
                              alt={game.away_team}
                              width={32}
                              height={32}
                            />
                          </Link>
                          <div className="covers-CoversScoreboard-gameBox-teamShortName-Records">
                            <Link to="#">
                              <span className="shortName">
                                {game.away_team.slice(0, 3)}
                              </span>{' '}
                              <span className="displayName">
                                {game.away_team}
                              </span>
                            </Link>
                            <span className="records">
                              ATS 0-0-0 • O/U 0-0-0
                            </span>
                          </div>
                        </div>
                        <div className="covers-CoversScoreboard-gameBox-Team">
                          <Link to="#">
                            <img
                              loading="lazy"
                              src={`images/${game.home_team}.svg`}
                              alt={game.home_team}
                              width={32}
                              height={32}
                            />
                          </Link>
                          <div className="covers-CoversScoreboard-gameBox-teamShortName-Records">
                            <Link to="#">
                              <span className="shortName">
                                {game.home_team.slice(0, 3)}
                              </span>{' '}
                              <span className="displayName">
                                {game.home_team}
                              </span>
                            </Link>
                            <span className="records">
                              ATS 0-0-0 • O/U 0-0-0
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="covers-CoversScoreboard-gameBox-Odds">
                        <div className="covers-CoversScoreboard-gameBox-TeamOdds odds-header">
                          <span className="covers-box">Spread</span>
                          <span className="covers-box">Total</span>
                          {/* <span className="covers-box">M/L</span> */}
                        </div>
                        <div className="covers-CoversScoreboard-gameBox-TeamOdds odds-data">
                          <span className="covers-box">
                            {awayOutcome?.point} <span>{awayOutcome?.price || 'N/A'}</span>
                          </span>{' '}
                          <span className="covers-box">
                            o{overOutcome?.point} <span>{overOutcome?.price || 'N/A'}</span>
                          </span>{' '}
                          {/* <span className="covers-box">+125</span> */}
                        </div>
                        <div className="covers-CoversScoreboard-gameBox-TeamOdds odds-data">
                          <span className="covers-box">
                            {homeOutcome?.point} <span>{homeOutcome?.price || 'N/A'}</span>
                          </span>{' '}
                          <span className="covers-box">
                            u{underOutcome?.point} <span>{underOutcome?.price || 'N/A'}</span>
                          </span>{' '}
                          {/* <span className="covers-box">-150</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
          );
        })
      ) : (
        <div className="no-data">No games available for the selected date range.</div>
      )}
      </div>
 
            </div>
          </div>
    
    </div>
  );
};

export default ScoresMachup;
