import React ,{useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './membership.css';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
import config from '../config';


const Membership = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [hasCartItem, setHasCartItem] = useState(false);
    const [userData, setUserData] = useState([]);


    useEffect(() => {
        const token = localStorage.getItem('token');

        if (token) {
            fetchUserData(token);
        } else {
            setLoading(false); 
        }
    }, [navigate]);

    const fetchUserData = async (token) => {
        try {
            const response = await fetch(`${config.baseUrl}/welcome`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            });
            if (response.ok) {
                const data = await response.json();
                setUserData(data); // Store user data in state
                //fetchMembershipData(token, data.id); // Call fetchMembershipData with user_id
            } else {
                console.error('Failed to fetch user data');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };
    console.log(userData, "userData")
  

    const handleOrderNow = (tierName, price) => {
        const cartItems = [{ tierName, price }];
        localStorage.setItem('cart', JSON.stringify(cartItems));
        setHasCartItem(true);
        navigate('/checkout', { state: { tierName, price } });
    };

    // const handleOrderNow = (tierName, price) => {
    //     const token = localStorage.getItem('token');
    //     if (!token) {
    //         navigate('/login');
    //         return;
    //     }

    //     const cartItems = [{ tierName, price }];
    //     localStorage.setItem('cart', JSON.stringify(cartItems));
    //     setHasCartItem(true);
    //     navigate('/checkout', { state: { tierName, price } });
    // };

    const handleEmailChange = (e) => {
        const trimmedValue = e.target.value.trim();
        setEmail(trimmedValue);
    };

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setMessage("Please enter a valid email address");
            return;
        }

        try {
            const response = await fetch(`${config.baseUrl}/add-user-email`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_mail_name: email }),
            });

            if (response.ok) {
                setMessage("Email successfully submitted!");
                setEmail("");
                setTimeout(() => setMessage(""), 2000);
            } else {
                setMessage("Already Submitted");
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage("Network error occurred.");
        }
    };

    const currentTier = userData?.data?.membership;
    console.log(currentTier,"current Tier")
    return (
        <>
        <Header/>
            <section className="membership-part">
                <div className="container">
                    <div className="membership-heading">
                        <h2>Our Membership Menu</h2>
                        <h5>SIGN UP FOR FREE AND EXPLORE OUR PREMIUM OPTIONS</h5>
                        <p>
                            Create your free account with just your email, and instantly access
                            exclusive tips, valuable resources, and entry into our complimentary
                            Survivor Pool contests. Ready for more? Upgrade to unlock deeper
                            insights and bigger opportunities.
                        </p>
                        <div className="membership-box">
                            <form onSubmit={handleEmailSubmit}> {/* Add form to handle submission */}
                                <input
                                    type="text"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={handleEmailChange} // Handle input changes
                                    required
                                />
                                <input type="submit" value="Sign Up Now" className='membershipsubmit-desk'/>
                                <div className='membershipsubmit-mob '>
                                <input type="submit" value="Submit" className='membershipsubmit-mob1'/>
                                </div>
                            </form>
                            {message && <p>{message}</p>} {/* Display success or error message */}
                        </div>
                    </div>
                </div>
                <div className="membership-menu">
                    <div className="container">
                        <div className="menu-member">
                            <div className="menu-part1">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="menu-images">
                                            <img src="images/bagelsmenu1.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="surviour-right">
                                            <h5>TIER 1</h5>
                                            <h6>
                                                <i className="fa fa-bolt" aria-hidden="true" />
                                                Early Bird Price
                                            </h6>
                                            <h4>$59.99 <span className='deskmon'>Monthly</span><span className='mobmon'>/mo</span></h4>
                                            <div className="meber-bagel1">
                                                <div className="left-bagel1">
                                                    <h3>BAGEL</h3>
                                                    <div className="dot-part" />
                                                    <h3>
                                                        <s>$89.99<span className='deskmon'>Monthly</span><span className='mobmon'>/mo</span></s>
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="surviour-list1">
                                                <ul>
                                                    <li>Weekly Survivor advice with podcast</li>
                                                    <li>Access to Wade’s Survivor Grid</li>
                                                    <li>
                                                        Weekly NFL Circa Contest picks (5 picks per week) with
                                                        podcast
                                                    </li>
                                                </ul>
                                                <div className="Surviour-btn">
                                                {currentTier === "Tier1" ? (
                                                        <button disabled>Already a Member</button>
                                                    ) : (
                                                        <button onClick={() => handleOrderNow('Tier1', 59.99)}>Order Now</button>
                                                    )}
                                                    {currentTier === "Tier1" && (
                                                        <p>You already have a membership for Tier 1. Upgrade to Tier 2 or Tier 3.</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="menu-part1">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="menu-images">
                                            <img src="images/bagelsmenu2.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="surviour-right">
                                            <h5>TIER 2</h5>
                                            <h6>
                                                <i className="fa fa-bolt" aria-hidden="true" />
                                                Early Bird Price
                                            </h6>
                                            <h4 className="hide-mobprice">$109.99 <span className='deskmon'>Monthly</span><span className='mobmon'>/mo</span></h4>
                                            <div className="meber-bagel1 desktop-membership">
                                                <div className="left-bagel1">
                                                    <h3>BAGEL WITH SPREAD</h3>
                                                    <div className="dot-part" />
                                                    <h3>
                                                        <s>$149.99 Monthly</s>
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="meber-bagel1 mobile-membership">
                                                <div className="left-bagel1">
                                                    <h3>BAGEL WITH </h3>
                            
                                                    <h4>$109.99<span className='deskmon'>Monthly</span><span className='mobmon'>/mo</span></h4>
                                                </div>
                                                <div className="left-bagel1">
                                                    <h3>SPREAD</h3>
                                                    <div className="dot-part" />
                                                    <h3>
                                                        <s>$149.99/mo</s>
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="surviour-list1">
                                                <p>Everything from Tier 1: Plus Wade's Deli, which includes:</p>
                                                <ul>
                                                    <li>Pregame NFL Picks</li>
                                                    <li>Pregame College Picks</li>
                                                    <li>Betting Advice through Podcast and Articles</li>
                                                </ul>
                                                <div className="Surviour-btn">
                                                {currentTier === "Tier2" ? (
                                                        <button disabled>Already a Member</button>
                                                    ) : (
                                                        <button onClick={() => handleOrderNow('Tier2', 109.99)}>Order Now</button>
                                                    )}
                                                    {currentTier === "Tier2" && (
                                                        <p>You can upgrade to Tier 3 or downgrade to Tier 1.</p>
                                                    )}
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="menu-part1">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="menu-images">
                                            <img src="images/bagelsmenu3.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="surviour-right">
                                            <h5>TIER 3</h5>
                                            <h6>
                                                <i className="fa fa-bolt" aria-hidden="true" />
                                                Early Bird Price
                                            </h6>
                                            <h4 className="hide-mobprice">$159.99<span className='deskmon'>Monthly</span><span className='mobmon'>/mo</span></h4>
                                            <div className="meber-bagel1 desktop-membership">
                                                <div className="left-bagel1">
                                                    <h3>BAGEL, SPREAD, &amp; LOCKS</h3>
                                                    <div className="dot-part" />
                                                    <h3>
                                                        <s>$199.99 Monthly</s>
                                                    </h3>
                                                </div>
                                            </div>

                                            <div className="meber-bagel1 mobile-membership">
                                                <div className="left-bagel1">
                                                    <h3>BAGEL, SPREAD,</h3>
                            
                                                    <h4>$159.99<span className='deskmon'>Monthly</span><span className='mobmon'>/mo</span></h4>
                                                </div>
                                                <div className="left-bagel1">
                                                    <h3>& LOCKS</h3>
                                                    <div className="dot-part" />
                                                    <h3>
                                                        <s>$199.99/mo</s>
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="surviour-list1">
                                                <p>Everything from Tier 1 & 2: Plus Adam's Bakery which includes:</p>
                                                <ul>
                                                    <li>Wagers on all sports</li>
                                                    <li>Future Bets</li>
                                                    <li>Access to picks on NFL, NCAAF, NCAAB, NBA, Golf, Men's & Women's Tennis, MLB</li>
                                                    <li>Full access to Adam's live bets</li>
                                                    <li>Video recaps breaking down the thought process behind each wager</li>
                                                    <li>Weekly Q&A streams with Adam</li>
                                                    <li>Year-round content and winning strategies</li>
                                                </ul>
                                                <div className="Surviour-btn">
                                                {currentTier === "Tier3" ? (
                                                        <button disabled>Already a Member</button>
                                                    ) : (
                                                        <button onClick={() => handleOrderNow('Tier3', 159.99)}>Order Now</button>
                                                    )}
                                                    {currentTier === "Tier3" && (
                                                        <p>You can downgrade to Tier 1 or Tier 2.</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>

        </>
    )
}

export default Membership