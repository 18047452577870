import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './Pages/Homepage';
import NotFound from './404';
import Login from './User/Login';
import SignUp from './User/Register';
import CheckOut from './Pages/Checkout';
import ForgetPassword from './User/ForgetPassword';
import TermCondition from './Pages/TermCondition';
import FootballBetting from './Pages/FootballBetting';
import Privacy from './Pages/Privacy';
import SurviourGrid from './Pages/SurviourGrid';
import Membership from './Pages/Membership';
import Future from './Pages/Future';
import WadeBakery from './Pages/WadeBakery';
import AdamKichen from './Pages/AdamKichen';
// import AboutUs from './Pages/AboutUs';
import Account from './Pages/Account';
import ContactUs from './Pages/ContactUs';
import Thankyou from './Pages/Thankyou';
import Dashboard from './Pages/Dashboard';
import SurvivorPool from './Pages/SurvivorPool/SurvivorPool';
import CustomTabs from './Components/Homepage/CustomTabs';
import ResetPassword from './User/ResetPassword';

// const Home = lazy(() => import('./Pages/Homepage'));
// const NotFound = lazy(() => import('./404'));
// const Login = lazy(() => import('./User/Login'));
// const SignUp = lazy(() => import('./User/Register'));
// const CheckOut = lazy(() => import('./Pages/Checkout'));
// const ForgetPassword = lazy(() => import('./User/ForgetPassword'));
// const TermCondition = lazy(() => import('./Pages/TermCondition'));
// const FootballBetting = lazy(() => import('./Pages/FootballBetting'));
// const Privacy = lazy(() => import('./Pages/Privacy'));
// const SurviourGrid = lazy(() => import('./Pages/SurviourGrid'));
// const Membership = lazy(() => import('./Pages/Membership'));
// const Future = lazy(() => import('./Pages/Future'));
// const WadeBakery = lazy(() => import('./Pages/WadeBakery'));
// const AdamKichen = lazy(() => import('./Pages/AdamKichen'));
// const Account = lazy(() => import('./Pages/Account'));
// const ContactUs = lazy(() => import('./Pages/ContactUs'));
// const Thankyou = lazy(() => import('./Pages/Thankyou'));
// const Dashboard = lazy(() => import('./Pages/Dashboard'));
// const SurvivorPool = lazy(() => import('./Pages/SurvivorPool/SurvivorPool'));
// const CustomTabs = lazy(() => import('./Components/Homepage/CustomTabs')); // Uncomment if needed



function App() {
  return (
    <Router>
       <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route exact path='/login' element={<Login />} />
        <Route exact path='/sign-up' element={<SignUp />} />
        <Route exact path='/forget-password' element={<ForgetPassword />} />
        <Route exact path='/term-condition' element={<TermCondition />} />
       <Route exact path='/football-betting' element={<FootballBetting />} />
        <Route exact path='/privacy' element={<Privacy />} />
        <Route exact path='/survivor-hub' element={<SurviourGrid />} />
        <Route exact path='/survivor-pool' element={<SurvivorPool />} />
        <Route exact path='/membership' element={<Membership />} />
        <Route exact path='/nfl-contest' element={<Future />} />
        <Route exact path='/wade-deli' element={<WadeBakery/>} />
        <Route exact path='/adam-bakery' element={<AdamKichen/>} />
        <Route exact path='/account' element={<Account/>} />
        <Route exact path='/checkout' element={<CheckOut/>} />
        <Route exact path='/contact' element={<ContactUs/>} />
        <Route exact path='/thankyou' element={<Thankyou/>} />
        <Route exact path='/dashboard' element={<Dashboard/>} />
        <Route exact path='/customtab' element={<CustomTabs/>} />
        <Route exact path='/reset-password' element={<ResetPassword/>} />
      </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
