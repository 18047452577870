import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';


const Header = () => {
  const [firstName, setFirstName] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [membershipDropdownOpen, setMembershipDropdownOpen] = useState(false);
  const [isOutOfPool, setIsOutOfPool] = useState(false);
  const [outOfPoolMessage, setOutOfPoolMessage] = useState(''); 
  const [cartItemCount, setCartItemCount] = useState(0);
  const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log(token)
    const storedFirstName = localStorage.getItem('firstName');
    const userId = localStorage.getItem('userId');
    if (token && storedFirstName) {
      setFirstName(storedFirstName);
    }
    if(userId){
      fetchUserResults(userId)
    }
  }, []);

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItemCount(storedCart.length);
  },[])

  const fetchUserResults = async (userId) => {
    try {
      const response = await fetch(`${config.baseUrl}/fetchresult/${userId}`);
      const data = await response.json();

      if (response.ok) {
        // Check if any result is 1 (lose) and mark user as out of pool
        const hasLost = data.data.some((selection) => selection.result === 1);
        if (hasLost) {
          setIsOutOfPool(true);
        }
      } else {
        console.error('Failed to fetch results:', data.message);
      }
    } catch (error) {
      console.error('An error occurred while fetching results:', error);
    }
  };


  const handleLogout = async () => {
    try {
      const response = await fetch(`${config.baseUrl}/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        localStorage.removeItem('token');
        localStorage.removeItem('firstName');
        localStorage.removeItem('cart');
        setFirstName('');
        Swal.fire({
          icon: 'success',
          title: 'Logout Successfully',
          showConfirmButton: false,
          timer: 2500, // Adjust the timer to control how long the message is displayed
        });

        // Navigate to the login page after the timer ends
        setTimeout(() => {
          navigate('/');
        }, 2500);

      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('An error occurred during logout:', error);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleMembershipDropdown = () => {
    setMembershipDropdownOpen(true);
  };

  const toggleMenu = () => {
    document.querySelector(".menu-center").classList.toggle("mystyle");
  };

  const handleScrollToSection = () => {
    if (window.location.pathname !== "/") {
      // If not on the homepage, navigate to it
      navigate("/");

      // Set a delay to ensure the page navigation is complete
      setTimeout(() => {
        const section = document.getElementById('about-us-section');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300); // Adjust this delay if necessary
    } else {
      // If already on the homepage, scroll directly
      const section = document.getElementById('about-us-section');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const handleSurvivorNavigation = (e) => {
    if (isOutOfPool) {
      e.preventDefault();
      setOutOfPoolMessage('You are out of the pool!');
    }
  };



  return (
    <>
      <section className="header-part1">
        <div className="header-part">
          <div className="container">
            <p>Enter our <strong>FREE</strong> NFL Survivor Pool and <strong>WIN $4,500 CASH </strong><i class="fa fa-caret-right" aria-hidden="true"></i>
            </p>
          </div>
        </div>

        <div className="main-header">
          <div className="container">
            <div className="main-2">
              <div class="menu-btn">
                <Link to="/" className="logo-left">
                  <img src="images/logonew.png" alt="" />
                </Link>
                <button type="button" className='click' onClick={toggleMenu}><i class="fa fa-bars" aria-hidden="true"></i>
                </button>
              </div>
              <div className="menu-center">
                <ul>

                  <li>
                    <Link to="/membership">Memberships</Link>

                  </li>
                  <li>
                    <Link onMouseEnter={toggleMembershipDropdown}>Survivor Hub</Link>
                    {membershipDropdownOpen && (
                      <ul className="dropdown-menu ">
                        {/* <li> <Link to="/surviour-grid">Survivor Grid</Link></li> */}
                        <li>
                          <Link to="/survivor-pool" 
                          className="dropdown-text"
                          onClick={handleSurvivorNavigation}
                          >Survivor Pool</Link>
                        </li>
                        <li>
                          <Link to="/survivor-hub"  
                          className="dropdown-text"
                          >Survivor Grid</Link>
                        </li>
                      </ul>
                    )}
                  </li>
                  <li>
                    <Link to="/nfl-contest">NFL Contest Picks</Link>
                  </li>
                  <li>
                    <Link to="/wade-deli">Wade's Deli</Link>
                  </li>
                  <li>
                    <Link to="/adam-bakery">Adam's Bakery</Link>
                  </li>


                  <li>
                    <button onClick={handleScrollToSection}>About Us</button> {/* Updated here */}
                  </li>
                </ul>
                <div className="sign-right">
                  {/* <div className="search-box1">
                  <img src="images/searchicon.png" alt="" />
                </div> */}
                  <div className="account1">
                    <img src="images/account.png" alt="" />
                    <ul>
                      <li>
                        {firstName ? (
                          <>
                            <div className='username'>
                              <Link to="/account">{firstName}</Link> {/* Directly link to dashboard */}
                            </div>
                            <div>
                              <Link onClick={handleLogout}>Logout</Link>
                            </div>
                          </>
                        ) : (
                          <div className=''>
                            <Link to="/login">LOGIN</Link>
                          </div>
                        )}

                      </li>
                    </ul>
                  </div>
                  <div className="cart-img">
                  <Link to="/checkout">
                    {cartItemCount > 0 && (
                      <span className="cart-count">{cartItemCount}</span> // Show count when items in cart
                    )}
                    <img src="images/cart-icon.png" alt="Cart" />
                  </Link>
                </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        {outOfPoolMessage && (
          <div className="out-of-pool-message">
            <p>{outOfPoolMessage}</p>
          </div>
        )}
      </section>

    </>

  )
}


export default Header