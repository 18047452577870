import React, { useState } from 'react';
import InnerHeader from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
import { Link, useNavigate } from 'react-router-dom';
import config from '../config';
import Swal from 'sweetalert2';

const Register = () => {
  // Define state variables to hold user input
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [promo_code, setPromoCode] = useState('');
  const [email, setEmail] = useState('');
  const [phoneno, setPhoneNo] = useState('');
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!isChecked) {
      setError('You must agree to the terms and privacy policy.');
      return;
    }

    if (password.length < 8) {
      setError('Password must be at least 8 characters long');
      return;
    }

    if (password !== confirm_password) {
      setError('Password and Confirm Password do not match');
      return;
    }

    const data = {
      first_name,
      last_name,
      promo_code,
      phoneno,
      email,
      password,
      confirm_password: confirm_password,
    };

    try {
      const response = await fetch(`${config.baseUrl}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // Show success message using Swal
        Swal.fire({
          icon: 'success',
          title: 'User Registered Successfully',
          showConfirmButton: false,
          timer: 2500, // Adjust the timer to control how long the message is displayed
        });

        // Navigate to the login page after the timer ends
        setTimeout(() => {
          navigate('/login');
        }, 2500); // Match the Swal timer to avoid abrupt navigation
      } else {
        const errorData = await response.json();
        if (errorData && errorData.msg === 'This email already exists') {
          setError('This email is already in use');
        } else {
          setError('Registration failed. Please try again.');
        }
      }
    } catch (error) {
      setError('An error occurred during registration.');
    }
  };

  return (
    <>
      <InnerHeader />
      <section className="login-part">
        <div className="container">
          <div className="membership-heading">
            <h2>Create an Account</h2>
            <h5>SIGN UP FOR FREE AND EXPLORE OUR PREMIUM OPTIONS</h5>
            <p>Create your free account and instantly access exclusive tips, valuable resources, and entry into our complimentary Survivor Pool contests. Ready for more? Upgrade to unlock deeper insights and bigger opportunities.</p>
          </div>
          <div className='signup-form-sub'>
            <form onSubmit={handleFormSubmit}>
              <div className='row'>
                <div className='col-md-6'>
                  <input
                    type="text"
                    placeholder="First Name"
                    maxLength={15}
                    required
                    value={first_name}
                    onChange={(e) => setFirstName(e.target.value)}
                    onInvalid={(e) => e.target.setCustomValidity('Please enter your first name')}
                    onInput={(e) => e.target.setCustomValidity('')} // Reset custom validity on input
                  />
                </div>
                <div className='col-md-6'>
                  <input
                    type="text"
                    placeholder="Last Name"
                    required
                    maxLength={15}
                    value={last_name}
                    onChange={(e) => setLastName(e.target.value)}
                    onInvalid={(e) => e.target.setCustomValidity('Please enter your last name')}
                    onInput={(e) => e.target.setCustomValidity('')} // Reset custom validity on input
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email Address"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onInvalid={(e) => e.target.setCustomValidity('Please enter a valid email address')}
                    onInput={(e) => e.target.setCustomValidity('')} // Reset custom validity on input
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <input
                    type="tel"  // 'tel' is better suited for phone numbers
                    className="form-control"
                    placeholder="Phone Number"
                    required
                    value={phoneno}
                    maxLength={10}  // Limit input to 10 characters
                    pattern="\d{10}" // Enforce only digits (0-9) and exactly 10 of them
                    onChange={(e) => setPhoneNo(e.target.value)}
                    onInvalid={(e) => e.target.setCustomValidity('Please enter a valid 10-digit phone number')}
                    onInput={(e) => e.target.setCustomValidity('')} // Reset custom validity on input
                  />
                </div>

              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    required
                    value={password}
                    maxLength={20}
                    onChange={(e) => setPassword(e.target.value)}
                    onInvalid={(e) => e.target.setCustomValidity('Please enter your password')}
                    onInput={(e) => e.target.setCustomValidity('')} // Reset custom validity on input
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirm Password"
                    required
                    maxLength={20}
                    value={confirm_password}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onInvalid={(e) => e.target.setCustomValidity('Please confirm your password')}
                    onInput={(e) => e.target.setCustomValidity('')} // Reset custom validity on input
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Promo Code"
                    value={promo_code}
                    onChange={(e) => setPromoCode(e.target.value)}
                    onInvalid={(e) => e.target.setCustomValidity('Please enter a Promo Code')}
                    onInput={(e) => e.target.setCustomValidity('')} // Reset custom validity on input
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  {/* <h5>By Creating an account you agree and accept our <Link to="/term-condition">Terms</Link> and <Link to="/privacy">Privacy Policy.</Link></h5> */}
                  <div className='registercheck'>
                  <label>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={() => setIsChecked(!isChecked)}
                      required
                    />
                  <span>
                    By providing your phone number, you agree to receive text messages from Spreads And Locks. Message and data rates may apply. Message frequency varies.&nbsp; 
                       <Link to="/term-condition">Terms & Conditions</Link> and <Link to="/privacy">Privacy Policy</Link>.
                  </span>
                  </label>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className="Surviour-btn signin-btn">
                    <input type="submit" value="Submit" />
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                  </div>
                </div>
              </div>
              <div className='forget-sign'>
                <div className='left-sign'>
                  <Link to="/forget-password" className='forget-name'>Forgot Password</Link>
                </div>
                <div className='left-sign'>
                  <Link to="/login" className='sign-up1'>Sign In</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Register;
